import React, { useState, useRef, useEffect } from "react";
import { useLocation, Link ,useNavigate} from "react-router-dom";

const RequestForm = ({
  jwtToken,
  signInUser,
  handleRefresh,
  setHandleRefresh,
}) => {
  const [formData, setFormData] = useState({
    transferEmail: "",
    parentDomainId: null,
    subdomainname: "",
    subdomainid: null,
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const inputRef = useRef({});
  const location = useLocation();
  const navigate = useNavigate();
  const stageEnv = process.env.REACT_APP_BACKEND_API_BASE_URL
  useEffect(() => {
    if (location && location.state) {
      const {
        domain = { FQDN: "", domainid: null },
        subdomain = { FQDN: "", ID: null },
      } = location.state;
      setFormData({
        ...formData,
        subdomainname: subdomain.FQDN,
        subdomainid: subdomain.ID,
        parentDomainId: domain.domainid,
      });
    }
  }, [location]);

  const errorField = (field) => {
    for (let input in inputRef.current) {
      if (input === field) {
        inputRef.current[input].focus();
        inputRef.current[input].style.borderColor = "#c33131";
      } else {
        inputRef.current[input].style.borderColor = "#d6dbd9";
      }
    }
  };
  const checkInputErrors = () => {
    const { transferEmail, parentDomainId, subdomainid } = formData;
    const emailPattern =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (transferEmail === "") {
      setErrorMessage("Transfer user email address is required");
      errorField("transferEmail");
      return true;
    } else if (transferEmail.length > 0 && !emailPattern.test(transferEmail)) {
      setErrorMessage("Invalid transfer user email formate");
      errorField("transferEmail");
      return true;
    } else if (parentDomainId === null) {
      setErrorMessage("Parent domain is required");
      return true;
    } else if (subdomainid === null) {
      setErrorMessage("Subdomain id is required");
      return true;
    } else {
      errorField("");
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { transferEmail, parentDomainId, subdomainid } = formData;
    const hasError = checkInputErrors();
    if (hasError) {
      setShowError(true);
      return;
    }
    setShowError(false);
    const requestData = {
      requesteremail: transferEmail.toLowerCase(),
      parentdomainid: parentDomainId,
      subdomainid: subdomainid,
    };
    const request = await fetch(
      // `https://us8q2gw5yg.execute-api.us-east-2.amazonaws.com/dev/subdomain`,
      // `https://201fzvfx02.execute-api.us-east-1.amazonaws.com/prod/subdomain`,
      `${stageEnv}/subdomain`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          "Authorization": jwtToken,
        },
        body: JSON.stringify(requestData),
      }
    );
    const content = await request.json();
    if (content.statusCode.toString() === "200") {
      setShowSuccess(true);
      setShowError(false);
      setTimeout(() => {
        navigate('/')
      }, 1000);
    } else {
      setShowError(true);
      setShowSuccess(false);
      setErrorMessage(content.message);
    }
    
  };

  return (
    <div className="container request-form">
      {showError && <div className="error-container">{errorMessage}</div>}
      {showSuccess && (
        <div className="success-container">
          Success! Email sent to the requester for acceptance.
        </div>
      )}
      <form>
        <div className="form-group">
          <label className="pb-3">Owner’s email address: {signInUser}</label>
          <br />
          <label className="pb-3">Subdomain: {formData.subdomainname}</label>
          <br />
          <label for="email">Transfer user email address</label>
          <input
            type="email"
            className="form-control"
            id="transferEmail"
            name="transferEmail"
            ref={(el) => (inputRef.current["transferEmail"] = el)}
            value={formData.email}
            onChange={(e) =>
              setFormData({ ...formData, transferEmail: e.target.value })
            }
          />
        </div>
        <Link
          to="/"
          className="btn btn-primary back"
          onClick={() => setHandleRefresh(!handleRefresh)}
        >
          Back
        </Link>
        {!showSuccess && (
          <button
            type="submit"
            className="btn btn-primary"
            onClick={handleSubmit}
          >
            Transfer
          </button>
        )}
      </form>
    </div>
  );
};

export default RequestForm;
