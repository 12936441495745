import { useParams } from "react-router-dom";
import "../style/style.css";

const Error = () => {
  const { domain } = useParams();
  return (
    <div className="error">
      <div className="loader">
        No redirect found{" "}
        {domain && <span className="res">{`for ${domain}`}</span>}
      </div>
    </div>
  );
};
export default Error;
