import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { Amplify, Auth } from "aws-amplify";
import awsExports from "./aws-exports";
import Domain from "./components/Domain";
import RequestForm from "./components/RequestForm";
import TransderDomain from "./components/TransferDomain";
import Error from "./components/Error";
import "./style/app.scss";
const Treeview = React.lazy(()=>import("./components/Treeview"))
Amplify.configure(awsExports);

function App() {
  const [isUserLogin, setIsUserLogin] = useState(false);
  const [domains, setDomains] = useState([]);
  const [loading, setLoading] = useState(false);
  const [jwtToken, setJWTToken] = useState("");
  const [signInUser, setSignInUser] = useState("");
  const [handleRefresh, setHandleRefresh] = useState(false);
  const stageEnv = process.env.REACT_APP_BACKEND_API_BASE_URL

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((res) => {
        const ownerEmail =
          res &&
          res.signInUserSession &&
          res.signInUserSession.idToken &&
          res.signInUserSession.idToken.payload &&
          res.signInUserSession.idToken.payload.email;
        setSignInUser(ownerEmail);
        setIsUserLogin(true);
        setJWTToken(res.signInUserSession.idToken.jwtToken);
      })
      .catch((err) => {
        setIsUserLogin(false);
      });
  }, [isUserLogin]);

  useEffect(() => {
    if (isUserLogin && jwtToken) {
      setLoading(true);
      list_access_domains();
    }
  }, [isUserLogin, jwtToken, handleRefresh]);
  
  const list_access_domains = async () => {
    const params = {
      method: "GET",
      headers: {
        "Authorization": jwtToken,
      },

    };
    const domainRequest = await fetch(
      // "https://us8q2gw5yg.execute-api.us-east-2.amazonaws.com/dev/domain/",
      // "https://201fzvfx02.execute-api.us-east-1.amazonaws.com/prod/domain/",
      `${stageEnv}/domain/`,
      params
    );
    const response = await domainRequest.json();
    console.log(response)
    if (
      response &&
      Array.isArray(response.response) &&
      response.response.length > 0
    ) {
      setDomains(response.response);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const ErrorPageRoute = () => (
    <Routes>
      <Route path="/error/:domain" element={<Error />} />
      <Route path="/error" element={<Error />} />
    </Routes>
  );

  return (
    <div className="App">
         {/* <Routes>
              <Route
                    path="/new-domain"
                    element={
                     <Treeview/>
                    }
                  />
                  </Routes> */}
      {isUserLogin ? (
        <>
          {loading ? (
            <div className="message-box">
              <iframe
                src="https://giphy.com/embed/swhRkVYLJDrCE"
                frameBorder={0}
              />
            </div>
          ) : (
            <>
              <div className="nav">
                <ul>
                  {signInUser && <li>User: {signInUser}</li>}
                  <li className="logout" onClick={() => Auth.signOut()}>
                    Logout
                  </li>
                </ul>
              </div>
              {domains.length > 0 ? (
                <Routes>
                  <Route
                    path="/"
                    element={
                      <Domain
                        jwtToken={jwtToken}
                        domains={domains}
                        handleRefresh={handleRefresh}
                        setHandleRefresh={setHandleRefresh}
                      />
                    }
                  />
                  <Route
                    path="/request"
                    element={
                      <RequestForm
                        jwtToken={jwtToken}
                        signInUser={signInUser}
                        handleRefresh={handleRefresh}
                        setHandleRefresh={setHandleRefresh}
                      />
                    }
                  />
                  <Route
                    path="/transfer"
                    element={
                      <TransderDomain
                        jwtToken={jwtToken}
                        signInUser={signInUser}
                        handleRefresh={handleRefresh}
                        setHandleRefresh={setHandleRefresh}
                      />
                    }
                  />
                  <Route path="/error/:domain" element={<Error />} />
                  <Route path="/error" element={<Error />} />
                </Routes>
              ) : (
                <div className="domain-message-box denied">
                  {/* <Treeview/> */}
                  <ErrorPageRoute />
                  <h1>No domain available for the login user !!!</h1>
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <div className="social-login">
          <ErrorPageRoute />
          <button
            type="button"
            className="login-with-google-btn"
            onClick={() => Auth.federatedSignIn({ provider: "Google" })}
          >
            Sign in with Google
          </button>
        </div>
      )}
    </div>
  );
}

export default App;
