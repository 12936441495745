import React, { useState, useRef, useEffect } from "react";
import { useLocation, Link,useNavigate } from "react-router-dom";

const RequestForm = ({
  jwtToken,
  signInUser,
  handleRefresh,
  setHandleRefresh,
}) => {
  const [formData, setFormData] = useState({
    requesterEmail: "",
    parentDomain: null,
    requestedSubdomainName: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const inputRef = useRef({});
  const location = useLocation();
  const navigate = useNavigate();
  const stageEnv = process.env.REACT_APP_BACKEND_API_BASE_URL
  useEffect(() => {
    if (location && location.state) {
      const { domain = { FQDN: "", domainid: "" } } = location.state;
      setFormData({
        ...formData,
        parentDomain: {
          label: domain.FQDN,
          value: domain.domainid,
        },
      });
    }
  }, [location]);

  const errorField = (field) => {
    for (let input in inputRef.current) {
      if (input === field) {
        inputRef.current[input].focus();
        inputRef.current[input].style.borderColor = "#c33131";
      } else {
        inputRef.current[input].style.borderColor = "#d6dbd9";
      }
    }
  };
  const checkInputErrors = () => {
    const { requesterEmail, parentDomain, requestedSubdomainName } = formData;
    const emailPattern =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (requesterEmail === "") {
      setErrorMessage("Email address of requester is required");
      errorField("requesterEmail");
      return true;
    } else if (
      requesterEmail.length > 0 &&
      !emailPattern.test(requesterEmail)
    ) {
      setErrorMessage("Invalid requester email formate");
      errorField("requesterEmail");
      return true;
    } else if (parentDomain === null) {
      setErrorMessage("Parent domain is required");
      return true;
    } else if (requestedSubdomainName === "") {
      setErrorMessage("Subdomain is required");
      errorField("requestedSubdomainName");
      return true;
    } else {
      errorField("");
      return false;
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedFormData = {
      ...formData,
      [name]: value,
    };
    setFormData(updatedFormData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { requesterEmail, parentDomain, requestedSubdomainName } = formData;
    const hasError = checkInputErrors();
    if (hasError) {
      setShowError(true);
      return;
    }
    setShowError(false);
    const requestData = {
      requesteremail: requesterEmail.toLowerCase(),
      parentdomainid: parentDomain.value,
      requestedsubdomainname: requestedSubdomainName,
    };
    const request = await fetch(
      // `https://us8q2gw5yg.execute-api.us-east-2.amazonaws.com/dev/createrequest`,
      // `https://201fzvfx02.execute-api.us-east-1.amazonaws.com/prod/createrequest`,
      `${stageEnv}/createrequest`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          "Authorization": jwtToken,
        },
        body: JSON.stringify(requestData),
      }
    );
    const content = await request.json();
    if (content.statusCode === 200) {
      setShowSuccess(true);
      setShowError(false);
      setTimeout(() => {
        navigate('/')
        setHandleRefresh(!handleRefresh)
      }, 1000);
    } else {
      setShowError(true);
      setShowSuccess(false);
      setErrorMessage(content.message);
    }
    
  };
  return (
    <div className="container request-form">
      {showError && <div className="error-container">{errorMessage}</div>}
      {showSuccess && (
        <div className="success-container" style={{backgroundColor: 'rgb(18, 143, 18)'}}>
          Success! Email sent to the requester for acceptance.
        </div>
        
      )}
      <form>
        <div className="form-group">
          <label className="pb-3">
            Domain owner’s email address: {signInUser}
          </label>
          <br />
          <label for="email">Email address of requester</label>
          <input
            type="email"
            className="form-control"
            id="requesterEmail"
            name="requesterEmail"
            ref={(el) => (inputRef.current["requesterEmail"] = el)}
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label for="requestedSubdomainName">New subdomain</label>
          <div className="subdomain">
            <input
              type="text"
              className="form-control"
              id="requestedSubdomainName"
              ref={(el) => (inputRef.current["requestedSubdomainName"] = el)}
              name="requestedSubdomainName"
              value={formData.requestedSubdomainName}
              onChange={handleChange}
            />
            <span>
              {formData?.parentDomain && `.${formData?.parentDomain.label}`}
            </span>
          </div>
        </div>
        <Link
          to="/"
          className="btn btn-primary back"
          onClick={() => setHandleRefresh(!handleRefresh)}
        >
          Back
        </Link>
        {!showSuccess && (
          <button
            type="submit"
            className="btn btn-primary"
            onClick={handleSubmit}
          >
            Create
          </button>
        )}
      </form>
    </div>
  );
};

export default RequestForm;
