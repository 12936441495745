const awsmobile = {
  aws_project_region: `${process.env.REACT_APP_AWS_PROJECT_REGION}`,
  aws_cognito_region: `${process.env.REACT_APP_AWS_COGNITO_REGION}`,
  aws_user_pools_id: `${process.env.REACT_APP_AWS_USER_POOLS_ID}`,
  aws_user_pools_web_client_id: `${process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID}`,
  oauth: {
    domain: `${process.env.REACT_APP_DOMAIN}`,
    // redirectSignIn: "http://localhost:3001",
    // redirectSignOut: "http://localhost:3001",
    // redirectSignIn: "https://testlogin.directory.domains/",
    // redirectSignOut: "https://testlogin.directory.domains/",
    redirectSignIn: `${process.env.REACT_APP_URL_SIGNIN}`,
    redirectSignOut: `${process.env.REACT_APP_URL_SIGNOUT}`,
    responseType: "code",
  },
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: ["GOOGLE"],
  aws_cognito_signup_attributes: [],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: [],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [
      "REQUIRES_LOWERCASE",
      "REQUIRES_UPPERCASE",
      "REQUIRES_NUMBERS",
      "REQUIRES_SYMBOLS",
    ],
  },
  aws_cognito_verification_mechanisms: ["EMAIL", "PHONE_NUMBER"],
  API: {
    endpoints: [
      {
        name: "biotouch",
        // endpoint: "https://us8q2gw5yg.execute-api.us-east-2.amazonaws.com/dev/",
        endpoint: `${process.env.REACT_APP_BACKEND_API_BASE_URL}`,
      },
    ],
  },
};
export default awsmobile;
