import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { AiOutlineExclamationCircle, AiOutlineDown } from "react-icons/ai";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";


const Domain = ({ jwtToken, domains, handleRefresh, setHandleRefresh }) => {
  const [requestDomains, setRequestDomains] = useState([]);
  const [activeDomains, setActiveDomains] = useState([]);
  const [subdomains, setSubdomains] = useState({});
  const [editableRowIndex, setEditableRowIndex] = useState(null);
  const [parentDomainIndex, setParentDomainIndex] = useState(null);
  const [redirectLink, setRedirectLink] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const inputRef = useRef();
  const stageEnv = process.env.REACT_APP_BACKEND_API_BASE_URL
  useEffect(() => {
    const requestedDomains = domains.filter((domain) => {
      return domain.domain_status !== "Active";
    });
    const managedDomains = domains.filter((domain) => {
      return domain.domain_status === "Active";
    });
    setRequestDomains(requestedDomains);
    setActiveDomains(managedDomains);
  }, [domains]);

  useEffect(() => {
    setCollapse(true);
  }, [parentDomainIndex]);

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        Pending approval - waiting for the owner of the parent domain to approve
        your request to manage this subdomain.
        <br />
        Reserved - pending your acceptance. Check your email for an invitation
        to accept control of this subdomain.
        <br />
        Active - available for you to specify your destination address.
        <br />
        Inactive - currently not available. Inquire with tech support at
        support@directory.domains.
        <br />
        Expired - the time frame to accept this domain has expired. You can make
        another request to control this subdomain.
        <br /> Transferred - control has transferred to another party.
      </Popover.Body>
    </Popover>
  );
  const showAction = (domain) => {
    if (domain.domain_status === "Active") {
      return (
        <Link to="/request" state={{ domain }}>
          Create new subdomain
        </Link>
      );
    } else if (domain.domain_status === "Reserved") {
      return <span onClick={() => handleClaim(domain.domainid)}>Claim</span>;
    }
  };

  // const isUrlValid = (userInput) => {
  //   var res = userInput.match(
  //     /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  //   );
  //   if (res == null) return false;
  //   else return true;
  // };

  const handleRedirectUpdate = async (id) => {
    if (redirectLink === "") {
      setError("Enter valid redirection URL...");
      inputRef.current.style.borderColor = "brown";
      return false;
    } else {
      setError("");
      const params = {
        method: "PUT",
        headers: {
          "Content-type": "application/json",
          "Authorization": jwtToken,
        },
        body: JSON.stringify({ domainid: id, redirect_url: redirectLink }),
      };
      const domainRequest = await fetch(
        // "https://us8q2gw5yg.execute-api.us-east-2.amazonaws.com/dev/domain/",
        // "https://201fzvfx02.execute-api.us-east-1.amazonaws.com/prod/domain/",
        `${stageEnv}/domain/`,
        params
      );
      const response = await domainRequest.json();
      if (response.statusCode === 200) {
        setSuccess(response.message);
        inputRef.current.style.borderColor = "green";
        setTimeout(() => {
          setEditableRowIndex(null);
          setHandleRefresh(!handleRefresh);
          setError("");
          setSuccess("");
        }, 1000);
      } else {
        setError(response.message);
        inputRef.current.style.borderColor = "brown";
      }
    }
  };

  const handleClaim = async (domainId) => {
    const claimRequest = {
      domain: domainId,
    };
    const request = await fetch(
      //  "https://us8q2gw5yg.execute-api.us-east-2.amazonaws.com/dev/claim",
      // "https://201fzvfx02.execute-api.us-east-1.amazonaws.com/prod/claim",
      `${stageEnv}/claim`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          "Authorization": jwtToken,
        },
        body: JSON.stringify(claimRequest),
      }
    );
    const response = await request.json();
    setHandleRefresh(!handleRefresh);
  };
  const handleSubdomains = async (e, domain, type, index) => {
    try {
      if (type === "manage") {
        setLoading(true);
        setParentDomainIndex(index);
        setCollapse(!collapse);
        const request = await fetch(
         // `https://us8q2gw5yg.execute-api.us-east-2.amazonaws.com/dev/subdomain?parentdomainid=${domain.domainid}`,
          // `https://201fzvfx02.execute-api.us-east-1.amazonaws.com/prod/subdomain?parentdomainid=${domain.domainid}`,
          `${stageEnv}/subdomain?parentdomainid=${domain.domainid}`,
          {
            method: "GET",
            headers: {
              "Content-type": "application/json",
              "Authorization": jwtToken,
            },
          }
        );
        const response = await request.json();
        if (
          response &&
          Array.isArray(response.response) &&
          response.response.length > 0
        ) {
          setSubdomains({
            ...subdomains,
            [domain.subdomainname]: response.response,
          });
          setLoading(false);
        } else {
          setSubdomains({
            ...subdomains,
            [domain.subdomainname]: [],
          });
          setLoading(false);
        }
      }
    } catch (err) {
      // console.log(err);
      setLoading(false);
    }
  };

  const domainTable = (type) => {
    const tableHeading =
      type === "request" ? "Requested Domain" : "Your Subdomains";
    return (
      <div className="table-content">
        <h1>{tableHeading}</h1>
        <table className="table table-bordered table-hover table-responsive">
          <thead>
            <tr className="table-primary">
              <th scope="col">Domain name</th>
              <th scope="col">
                Status{" "}
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={popover}
                >
                  <span className="title">
                    {<AiOutlineExclamationCircle />}
                  </span>
                </OverlayTrigger>
              </th>
              {type === "manage" && <th scope="col">Destination address</th>}
              <th scope="col">Action</th>
            </tr>
          </thead>
          {type === "manage" ? (
            <tbody>
              {activeDomains.map((domain, index) => {
                return (
                  <>
                    <tr
                      onClick={(e) => handleSubdomains(e, domain, type, index)}
                    >
                      <td>
                        <div className="fqdnName">
                          <a href={`http://${domain.FQDN}`} target="_blank">
                            {domain.FQDN}
                          </a>
                          <AiOutlineDown />
                        </div>
                      </td>
                      <td>{domain.domain_status}</td>
                      <td>
                        <div className="redirect">
                          {editableRowIndex === index ? (
                            <>
                              <div className="input-container">
                                <input
                                  type="text"
                                  name="redirectLink"
                                  placeholder="Add new redirect link"
                                  value={redirectLink}
                                  ref={inputRef}
                                  onChange={(e) =>
                                    setRedirectLink(e.target.value)
                                  }
                                />
                                {error && (
                                  <span className="errorMsg">{error}</span>
                                )}
                                {success && (
                                  <span className="successMsg">{success}</span>
                                )}
                              </div>
                              <div className="buttons">
                                <button
                                  className="btn btn-primary"
                                  onClick={() =>
                                    handleRedirectUpdate(domain.domainid)
                                  }
                                >
                                  Update
                                </button>
                                <button
                                  className="btn btn-danger cancel"
                                  onClick={() => {
                                    setEditableRowIndex(null);
                                    setError("");
                                  }}
                                >
                                  Cancel
                                </button>
                              </div>
                            </>
                          ) : (
                            <>
                              <a href={domain.redirect_url} target="_blank">
                                {domain.redirect_url}
                              </a>
                              <button
                                onClick={() => {
                                  setEditableRowIndex(index);
                                  setRedirectLink(domain.redirect_url);
                                  setError("");
                                }}
                                className="btn btn-primary"
                              >
                                Edit
                              </button>
                            </>
                          )}
                        </div>
                      </td>

                      <td>{showAction(domain)}</td>
                    </tr>
                    <tr
                      style={{
                        display:
                          index === parentDomainIndex
                            ? collapse
                              ? ""
                              : "none"
                            : "none",
                      }}
                    >
                      <td colSpan={4}>
                        {loading && index === parentDomainIndex ? (
                          <div className="table-loader">
                            <div className="spinner"></div>
                          </div>
                        ) : (
                          <>
                            {Object.keys(subdomains).length > 0 &&
                            subdomains[domain.subdomainname] &&
                            subdomains[domain.subdomainname].length > 0 ? (
                              <table className="table table-bordered table-hover table-responsive subdomain-table">
                                <thead>
                                  <tr className="table-primary">
                                    <th>subdomain</th>
                                    <th>Domain Manager</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {subdomains[domain.subdomainname].map(
                                    (subdomain) => {
                                      return (
                                        <tr>
                                          <td>
                                            <a
                                              href={`http://${subdomain.FQDN}`}
                                              target="_blank"
                                            >
                                              {subdomain.FQDN}
                                            </a>
                                          </td>
                                          <td>{subdomain.manager_email}</td>
                                          <td>{subdomain.STATUS}</td>
                                          <td>
                                            {" "}
                                            <Link
                                              to="/transfer"
                                              state={{ subdomain, domain }}
                                            >
                                              Transfer Domain
                                            </Link>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                                </tbody>
                              </table>
                            ) : (
                              <div className="no-sabdomain">
                                <p>No subdomains are available</p>
                              </div>
                            )}
                          </>
                        )}
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          ) : (
            <tbody>
              {requestDomains.map((domain) => {
                return (
                  <tr>
                    <td>
                      <div className="fqdnName">
                        <a href={`http://${domain.FQDN}`} target="_blank">
                          {domain.FQDN}
                        </a>
                      </div>
                    </td>
                    <td>{domain.domain_status}</td>
                    <td>{showAction(domain)}</td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
    );
  };

  return (
    <div className="domain-table">
      {requestDomains.length > 0 && domainTable("request")}
      {activeDomains.length > 0 && domainTable("manage")}
    </div>
  );
};

export default Domain;